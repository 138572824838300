<template>
  <div class="login-container">
    <div class="login-box">
      <div class="page-title">
        <h3>Login</h3>
        <p>You need to be an admin of an organization.</p>
      </div>

      <el-input placeholder="Email" v-model="email"></el-input>

      <el-input placeholder="Password" v-model="password" show-password></el-input>

      <div class="action-button">
        <el-button
          class="secondary-color"
          type="primary"
          @click="login"
          :loading="form_loading"
        >Submit</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data: () => ({
    email: '',
    password: '',
    form_loading: false
  }),
  computed: {},

  methods: {
    async login() {
      this.form_loading = true

      this.$store
        .dispatch('login', { email: this.email, password: this.password })
        .then(result => {
          this.$router.push('/').catch(() => {})
        })
        .catch(err => {
          this.$notify({
            title: 'Error',
            message: err,
            type: 'error'
          })
        })
        .finally(_ => {
          this.form_loading = false
        })
    }
  },

  async mounted() {}
}
</script>





<style lang="scss" scoped>
@import '@/theme/variables.scss';

.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 69px);
}

.login-box {
  padding: 15px;
  max-width: 500px;
  // border: 1px solid blue;
  width: 100%;
}

.el-input {
  margin-bottom: 15px;
}

.action-button {
  width: 100%;
  text-align: right;
}
</style>
